<template>
	<div class="page-form">

		<h1 v-if="title">
			{{title}}
		</h1>
		<div class="pageSubtitle">{{subtitle}}</div>

		<slot name="content"></slot>
		<slot name="nav"></slot>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	export default {
		name:"PageForm",
		components: {
		},
		props: {
			title: {
				type: String,
				required: false,
			},
			subtitle: {
				type: String,
				required: false,
			},
		}
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

	.page-form{
		width:1000px;
		margin:0 auto;

		@media screen and (max-width: 995px){
			width:100%;
			margin:0;
		}
	}
</style>
