// Cookiefirst
import config from "../config";

export default {

	methods: {
		putCookieFirstScriptInPlace(){
			console.log("--- COOKIEFIRST --------------------------------------");
			console.log("Deploying COOKIEFIRST");
			console.log("-----------------------------------------");
			let script = document.createElement('script');
			script.innerHTML = ``;
			script.setAttribute('src', `https://consent.cookiefirst.com/banner.js`);

			/* Put the code for this action here */
			script.setAttribute('data-cookiefirst-key',`b729101c-5e40-4393-aa13-1ab490f5c228`);

			document.body.prepend(script);
		}
	},
	beforeMount() {
		if(config.isWebsiteInProduction && !this.isPnGAction){
			this.putCookieFirstScriptInPlace();
		}
	}

}
